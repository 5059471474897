import React  from 'react'
import HeaderHome from '../../components/headerHome'

import './styles.scss'
// Componments
import {
  NavBar,
  Button
} from '../../components'
import {  useHistory } from "react-router-dom"
// Images
import graffic2 from '../../static/images/graffic-5.svg'
import qr from '../../static/images/qr.png'
import SessionStorage from "../../storage"
import Swal from "sweetalert2"
// api
import OuthApi from "../../api/outh.api"

const STORAGE = new SessionStorage();
const API = new OuthApi();

const PersonalFile: React.FC = () => {
  let history = useHistory();

  const closeSession = () => {
    STORAGE.clearStorage()
    history.push("/")
  }

  const handleDelete = () => {
    Swal.fire({
      text: 'Estas seguro de eliminar esta cuenta',

    })
    Swal.fire({
      text: 'Al confirmar esta operación, tu información será eliminada del sistema y no volverás a poder ingresar ¿Estás seguro?.',
      showDenyButton: true,
      confirmButtonText: 'Sí, eliminar',
      denyButtonText: `No, cancelar`,
    }).then(async (result) => {
      if (result.isConfirmed) {        
        const response = await API.DeleteAccount();

        switch (response?.status) {
          case 200:
              STORAGE.clearStorage()
              setTimeout(() => {
                history.push("/")
              }, 300);
            break;
          default:
            Swal.fire({
              icon: 'error',
              title: 'Ha ocurrido un error, intentalo más tarde',
            })
            break;
        }
      }
    })
  }


  return (
    <div className="home-contain personal-file-contain">
       
      <img className="graffic-home2" src={graffic2} alt="" />
      <HeaderHome return />
      <div className="personal-file-content">
        <h2>FICHA DE GUARDIA</h2>
        <p className='txt_center txt_p'>
          Se sugiere mostrar la siguiente información al profesional de
          salud que lo reciba en una situación de urgencia.
        </p>
        <h2 className='txt_italic'>Estoy bajo tratamiento <span className='fw-normal'>con inmunoterapia para el cáncer.</span></h2>
        <p className='txt_center txt_p'>
          Algunos de los eventos adversos relacionados a la inmunoterapia puede requerir
          tratamientos especificos como con inmunosupresores.
        </p>
        {/* <div className="cards">
          <div className='card'>
            <img src={card1} alt="" />
            <p>Confirmo que soy<br/>
              <b>paciente oncológico<br/>
              o familiar</b></p>
          </div>
          <div className='card'>
            <img src={card2} alt="" />
            <p>Estoy bajo tratamiento<br/>
            <b>de inmunoterapia<br/>
            para el cáncer</b></p>
          </div>
        </div> */}
        <div className='section-qr'>
          <h2 className='txt_center'>SE Sugiere ACCEDER A LA<br/>INFORMACIÓN DE SEGURIDAD<br/>EN EL PROSPECTO:</h2>
          <img className='qr' src={qr} alt="" />
          <h2><span className='fw-normal'>O INGRESAR </span><a href="https://conecta.msd.com.ar/therapeutic-areas/oncologia/keytruda_r/">AQUÍ</a></h2><br/><br/>
          <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}>
            <div onClick={closeSession}>
              <Button text='Cerrar Sesión' />
            </div>
            <div onClick={handleDelete}>
              <Button color='purple' text='Eliminar cuenta' />
            </div>
          </div>
        </div>
      </div>
      <NavBar />
    </div>
  )
}

export default PersonalFile
