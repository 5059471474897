class SessionStorage {

    saveSession (data:any) {
        localStorage.setItem("token", data.token)
        localStorage.setItem("refreshToken", data.refreshToken)
        localStorage.setItem("role", data.role)
        localStorage.setItem("username", data.username)
        localStorage.setItem("email", data.email)
        localStorage.setItem("auth", JSON.stringify(data.auth))
    }

    validationCode (value:boolean) {
        localStorage.setItem("validationCode", value.toString())
    }

    getValidationCode () {
        return (localStorage.getItem("validationCode") === "true")
    }

    saveDoctorRegistry (doctor: any) {
        localStorage.setItem("doctorRegistry", JSON.stringify(doctor))
    }

    getDoctorRegistry () {
        return JSON.parse(JSON.stringify(localStorage?.getItem("doctorRegistry")))
    }

    saveRegistryToken (token: any) {
        localStorage.setItem("registryToken", token)
    }

    getRegistryToken () {
        if (!localStorage.getItem("registryToken")) {
            localStorage.setItem("registryToken", "")
        }
        return JSON.parse(JSON.stringify(localStorage?.getItem("registryToken"))).toString()
    }

    getuserMail () {
        if (!localStorage.getItem("email")) {
            localStorage.setItem("email", "")
        }
        return JSON.parse(JSON.stringify(localStorage?.getItem("email"))).toString()
    }

    getUserName () {
        return localStorage.getItem("username")
    }

    getAuth () {
        return (localStorage.getItem("auth") === "true")
    }

    getToken () {
        if (!localStorage.getItem("token")) {
            localStorage.setItem("token", "")
        }
        return JSON.parse(JSON.stringify(localStorage?.getItem("token"))).toString()
    }

    getRefreshToken () {
        if (!localStorage.getItem("refreshToken")) {
            localStorage.setItem("refreshToken", "")
        }
        return JSON.parse(JSON.stringify(localStorage?.getItem("refreshToken"))).toString()
    }

    clearStorage () {
        localStorage.setItem("auth", JSON.stringify(false))
        localStorage.clear()
    }
}

export default SessionStorage