// Axios
import axios from "axios"
// Context
// SessionStorage
import SessionStorage from "../storage"
const STORAGE = new SessionStorage();

const getDateTime = () => {
    const currentdate = new Date(); 
        const year = currentdate.getFullYear()
        const month = currentdate.getMonth()+1
        const date = currentdate.getDate()
        const hours = currentdate.getHours()
        const minutes = currentdate.getMinutes()
        const seconds = currentdate.getSeconds()

        const datetime = `${year}-${(month < 10 ? `0${month}` : month)}-${(date < 10 ? `0${date}` : date)} ${(hours < 10 ? `0${hours}` : hours)}:${(minutes < 10 ? `0${minutes}` : minutes)}:${(seconds < 10 ? `0${seconds}` : seconds)}`
        return datetime
}

class OuthApi {
    private apiApolo: string;
    token: string
    refreshToken: string
    registryToken: string
    
    constructor() {
        this.apiApolo = "https://apolo-api.azurewebsites.net";
        //RES API DE PRUEBAS
        // this.apiApolo = "http://45.79.166.128:3001";
        this.token = STORAGE.getToken()
        this.refreshToken = STORAGE.getRefreshToken()
        this.registryToken = STORAGE.getRegistryToken()
    }
    
    async logIn (email: string, password: string) {
        try {
            const response = await axios({
            method: 'post',
                url: `${this.apiApolo}/auth/login`,
                data: {
                    email: email,
                    password: password
                }
            });
            return({
                status: response.status,
                data: response.data
            });
        } catch (error:any) {
            console.log(error);
        }
    }

    async CreateUser (username: string, email: string, password: string) {
        const dateTime = getDateTime() 
        const doctor = JSON.parse(STORAGE.getDoctorRegistry())        
        
        try {
                const response = await axios({
                method: 'post',
                url: `${this.apiApolo}/users`,
                headers: {
                    auth: this.token,
                },
                data: {
                    username: username,
                    email : email,
                    password: password,
                    role: "reader",
                    i_am_patient: true,
                    i_am_in_treatment: true,
                    dateTime: dateTime,
                    doctor: doctor.id
                }
            });
            return({
                status: response.status,
                data: response.data
            });
        } catch (error:any) {
            console.log(error);
            return error.response.data
        }
    }

    async ActivateUser (code: string) {  
        try {
            const response = await axios({
                method: 'put',
                url: `${this.apiApolo}/auth/activate-user`,
                headers: {
                    auth: STORAGE.getRegistryToken()
                },
                data: {
                    activationCode: code
                }
            });
            return({
                status: response.status,
                data: response.data
            });
        } catch (error:any) {
            console.log(error);
        }
    }
    async getTip () {
        try {
            const response = await axios({
                method: 'get',
                url: `${this.apiApolo}/users/tip/get`,
                headers: {
                    auth: STORAGE.getToken()
                },
            });
            return({
                status: response.status,
                data: response.data
            });
            
        } catch (error:any) {
            console.log(error);
        }
    }
    async getAllTips () {
        try {
            const response = await axios({
                method: 'get',
                url: `${this.apiApolo}/users/tips/get`,
                headers: {
                    auth: STORAGE.getToken()
                },
            });
            return({
                status: response.status,
                data: response.data
            });
            
        } catch (error:any) {
            console.log(error);
        }
    }
    async RefreshRoken () {
        try {
            const response = await axios({
                method: 'post',
                url: `${this.apiApolo}/auth/refresh-token`,
                headers: {
                    refresh: STORAGE.getRefreshToken()
                },
            });
            return({
                status: response.status,
                data: response.data
            });
        } catch (error:any) {
            console.log(error);
        }
    }

    async ForgotPassword (email:any) {
        try {
            const response = await axios({
                method: 'put',
                url: `${this.apiApolo}/auth/forgot-password`,
                data: {
                    email: email
                }
            });
            return({
                status: response.status,
                data: response.data
            });
        } catch (error:any) {
            console.log(error);
        }
    }

    async NewPassword (token:any,password:any) {  
        const dateTime = getDateTime()

        try {
            const response = await axios({
                method: 'put',
                url: `${this.apiApolo}/auth/new-password`,
                headers: {
                    reset: token
                },
                data: {
                    newPassword: password,
                    dateTime: dateTime
                }
            });
            return({
                status: response.status,
                data: response.data
            });
        } catch (error:any) {
            console.log(error);
        }
    }

    async LastAction () {
        const dateTime = getDateTime()

        try {
            const response = await axios({
                method: 'put',
                url: `${this.apiApolo}/users/updateActivityUser`,
                headers: {
                    auth: STORAGE.getToken()
                },
                data: {
                    dateTime: dateTime
                }
            });
            return({
                status: response.status,
                data: response.data
            });
        } catch (error:any) {
            console.log(error);
        }
    }

    async DeleteAccount () {        
        try {
            const response = await axios({
                method: 'delete',
                url: `${this.apiApolo}/users`,
                headers: {
                    auth: STORAGE.getToken()
                },
            });
            return({
                status: response.status,
                data: response.data
            });
        } catch (error:any) {
            console.log(error);
        }
    }

    async ValidateDoctor (code: any) {  
        try {
            const response = await axios({
                method: 'get',
                url: `${this.apiApolo}/doctors/validationCode`,
                headers: {
                    codeDoctor: code
                }
            });
            return({
                status: response.status,
                data: response.data
            });
        } catch (error:any) {
            console.log(error);
        }
    }
}

export default OuthApi;