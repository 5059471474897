import React, { useState } from 'react'
import Header from '../../components/header'
import Swal from 'sweetalert2'
// Componments
import {
  Button,
  InputText
} from '../../components'
// Styles
import './styles.scss'
// Images
import doctor from '../../static/images/doctor-8.png'
import arrowRigth from '../../static/images/arrow-rigth.svg'
import grafic from '../../static/images/graffic-1.svg'
import { useHistory, useParams } from "react-router-dom"
// api
import OuthApi from "../../api/outh.api"
const API = new OuthApi();


const Activation: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [stateInputs, setStateInputs] = useState({
    password: "",
    passwordConfirm: ""
  })
  const [validation, setValidation] = useState({
    valid: true,
    message: ""
  })
  const [stateUpdate, setStateUpdate] = useState(false)
  let history = useHistory();

  const { token } = useParams<any>();

  const handleChange = (e:any) => {
    setStateInputs(Object.assign(stateInputs, {
      [e.target.name]: e.target.value
    }))
    setStateUpdate(!stateUpdate)
  }

  const handleSend = async () => {
    setLoading(true)
    const regularExpression  = /^(?=.*[0-9!@#$%^&*._?])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*._?]{8,}$/;
    if (regularExpression.test(stateInputs.password)) {
      if (stateInputs.password !== "" && stateInputs.passwordConfirm !== "") {
        if (stateInputs.password === stateInputs.passwordConfirm) {       
          const response = await API.NewPassword(token,stateInputs.password)
    
          switch (response?.status) {
            case 200:
              setStateInputs({
                password: "",
                passwordConfirm: ""
              })
              Swal.fire({
                title: 'Contraseña cambiada',
                text: 'Ya puedes iniciar sesión',
                confirmButtonText: 'Iniciar sesión',
              }).then((result) => {
                if (result.isConfirmed) {
                  history.push("/")
                } 
              })
              break;
            default:
              Swal.fire({
                icon: 'error',
                title: 'Cambio no exitoso',
                text: 'Inténtalo más tarde'
              })
              break;
          }
        } else {
          setValidation({
            valid: false,
            message: "las contraseñas no coinciden*"
          }) 
        }
      } else {
        setValidation({
          valid: false,
          message: "Completa los campos*"
        })
      }
    }else{
      setValidation({
        valid: false,
        message: "Su contraseñas debe contener al menos 8 caracteres entre ellos mayúsculas, minúsculas, número y/o caracteres especiales*"
      })
    }
    setLoading(false)
  }
  
  return (
    <div className="login-content">
      <img className="grafic" src={grafic} alt="" />
      <div className="gradient-bottom"></div>

      <Header url="/" />
      <div className="content">     
      <h2 className='txt_center txt_black'>Ingresa tu nueva contraseña</h2>
        <div className="inputs-actions">
          <InputText onChange={handleChange} type='password' name="password" value={stateInputs.password} placeholder="Contraseña"/>
          <InputText onChange={handleChange} type="password" name="passwordConfirm" value={stateInputs.passwordConfirm} placeholder="Confirmar contraseña"/>
          {
           !validation.valid && <p className='text-val'>{ validation.message }*</p>
          }
        </div>
        <div onClick={handleSend}>
          {
            loading ? <div className="loader"></div> : <Button text="CAMBIAR" color="blue" size="lg" icon={arrowRigth} direcctionIcon="right"/>
          }
        </div>
        <img className='doctor' src={doctor} alt="" />
      </div>
    </div>
  )
}

export default Activation
