/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Header from '../../components/header'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
// Componments
import {
  Button,
  InputText
} from '../../components'
// Styles
import './styles.scss'
// Images
import doctor from '../../static/images/doctor-9.png'
import arrowRigth from '../../static/images/arrow-rigth.svg'
import grafic from '../../static/images/figura-1.png'
import { useHistory } from "react-router-dom"
// api
import OuthApi from "../../api/outh.api"
import SessionStorage from "../../storage"
const STORAGE = new SessionStorage();
const API = new OuthApi();

interface ParamsValues {
  token: string
}

const SignUpAdmin: React.FC = () => {
  const params = useParams<ParamsValues>()
  const [code, setCode] = useState("")
  const [validation, setValidation] = useState(true)
  const [loading, setLoading] = useState(false)
  let history = useHistory();

  const handleChange = (e:any) => {
    setCode(e.target.value)
  }

  const handleSend = async () => {
    setLoading(true)
    if (code !== "") {      
      const response = await API.ValidateDoctor(code)

      switch (response?.status) {
        case 200:
          STORAGE.validationCode(true)
          STORAGE.saveDoctorRegistry(response?.data.doctor)
          history.push("/sign-up")
        break;
        default:
          Swal.fire({
            icon: 'error',
            title: 'Error en el código',
            text: 'Ingresa un código correcto'
          })
        break;
      }
    } else {
      setValidation(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    if(params.token)
      STORAGE.saveRegistryToken(params.token)
	}, []);
  
  
  return (
    <div className="login-content">
      <img className="grafic" src={grafic} alt="" />
      <div className="gradient-bottom"></div>

      <Header url={"/"} />
      <div className="content">     
      <h2 className='txt_center fw-normal txt_black'>INGRESE EL <br/><b>CÓDIGO ÚNICO DE ACTIVACIÓN</b><br/></h2>
        <div className="inputs-actions">
          <InputText onChange={handleChange} value={code} placeholder="Código"/>
          {
           !validation && <p className='text-val'>Ingresa un código*</p>
          }
        </div>
        <div onClick={handleSend}>
          {
            loading ? <div className="loader"></div> : <Button text="ENVIAR CÓDIGO" color="blue" size="lg" icon={arrowRigth} direcctionIcon="right"/>
          }
        </div>
        <img src={doctor} alt="" />
      </div>
    </div>
  )
}

export default SignUpAdmin
