import React, { useEffect, useState } from "react"
import Header from "../../components/header"
import Swal from "sweetalert2"
// Styles
import "./styles.scss"
// Images
import grafic from "../../static/images/figura-2.png"
import { useHistory } from "react-router-dom"
import { Button, InputText, InputCheck } from "../../components"
// api
import OuthApi from "../../api/outh.api"
import SessionStorage from "../../storage"

import Terminos from "../../static/pdf/TyC-Apolo.pdf"
import Privacidad from "../../static/pdf/PRIVACIDAD.pdf"
import Consentimiento from "../../static/pdf/ConsentimientoApolo.pdf"

const API = new OuthApi()
const STORAGE = new SessionStorage()

const SingUp: React.FC = () => {
  const [loading, setLoading] = useState(false)
  let history = useHistory()

  const defaultValidation = {
    inputs: true,
    password_confirm: true,
    password_validate: true,
    confirm: true,
    confirmTwo: true,
    terms: true,
    privacity: true,
  }
  const [validations, setValidation] = useState(defaultValidation)

  const [stateInputs, setStateInputs] = useState({
    user: "",
    email: "",
    password: "",
    password_confirm: "",
    password_validate: "",
    confirm: "",
    confirmTwo: "",
    terms: "",
    privacity: "",
  })
  const [stateUpdate, setStateUpdate] = useState(false)

  useEffect(() => {
    if (!STORAGE.getValidationCode()) {
      //history.push(`/sign-up-admin`)
    } else {
      STORAGE.validationCode(false)
    }
    //login()
  }, [])

  const handleChange = (e: any) => {
    setValidation(defaultValidation)

    setStateInputs(
      Object.assign(stateInputs, {
        [e.target.name]: e.target.value,
      })
    )
    setStateUpdate(!stateUpdate)
  }

  const sendRegistry = async () => {
    setLoading(true)
    const response = await API.CreateUser(
      stateInputs.user,
      stateInputs.email,
      stateInputs.password
    )
    switch (response?.status) {
      case 200:
        if (response.data.token && response.data.token !== "") {
          STORAGE.saveRegistryToken(response.data.token)
          history.push(`/createUserScreen`)
        }
        break
      default:
        if (response.message) {
          Swal.fire({
            icon: "error",
            title: "Correo electrónico ya está en uso",
            text: "Ingresa un nuevo correo",
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Regístro no exitoso",
            text: "Inténtalo más tarde",
          })
        }
        break
    }
    setLoading(false)
  }

  const handleValidation = () => {
    let validation = true
    let inputsValidation = validations
    const regularExpression =
      /^(?=.*[0-9!@#$%^&*._?])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*._?]{8,}$/

    if (
      stateInputs.user === "" &&
      stateInputs.email === "" &&
      stateInputs.password === ""
    ) {
      inputsValidation = { ...inputsValidation, inputs: false }
      validation = false
    }
    if (!regularExpression.test(stateInputs.password)) {
      inputsValidation = { ...inputsValidation, password_validate: false }
      validation = false
    }
    if (stateInputs.password !== stateInputs.password_confirm) {
      inputsValidation = { ...inputsValidation, password_confirm: false }
      validation = false
    }
    if (stateInputs.confirm === "") {
      inputsValidation = { ...inputsValidation, confirm: false }
      validation = false
    }
    if (stateInputs.confirmTwo === "") {
      inputsValidation = { ...inputsValidation, confirmTwo: false }
      validation = false
    }
    if (stateInputs.terms === "") {
      inputsValidation = { ...inputsValidation, terms: false }
      validation = false
    }
    if (stateInputs.privacity === "") {
      inputsValidation = { ...inputsValidation, privacity: false }
      validation = false
    }
    setValidation(inputsValidation)
    setStateUpdate(!stateUpdate)

    if (validation) {
      sendRegistry()
    }
  }

  return (
    <div className="singup-content">
      <img className="grafic" src={grafic} alt="" />
      <div className="gradient-bottom"></div>

      <Header url={"/"} />
      <div className="content">
        <div className="figure-doctor d_flex justify_end">
          <div className="text-figure">
            <h2 className="txt_09 txt_bold txt_primary">
              ¡TE DAMOS LA BIENVENIDA!
            </h2>
            <p className="txt_09 txt_primary">
              Este espacio está creado para vos.
              <br /> Para tu salud, <b>para tu bienestar</b>.
            </p>
          </div>
        </div>
        <div>
          <h1 className="txt_bold txt_primary">REGÍSTRATE</h1>
        </div>
        <div className="inputs-actions">
          <InputText
            onChange={handleChange}
            value={stateInputs.user}
            name="user"
            placeholder="Nombre de usuario o ALIAS"
          />
          <InputText
            onChange={handleChange}
            value={stateInputs.email}
            name="email"
            placeholder="Correo Electrónico"
          />
          <InputText
            onChange={handleChange}
            value={stateInputs.password}
            type="password"
            name="password"
            placeholder="Contraseña"
          />
          <InputText
            onChange={handleChange}
            value={stateInputs.password_confirm}
            type="password"
            name="password_confirm"
            placeholder="Confirmar Contraseña"
          />

          {!validations.inputs && (
            <p className="text-val">Completa todos los campos*</p>
          )}

          {!validations.password_confirm && (
            <p className="text-val">Las contraseñas no coinciden*</p>
          )}

          {!validations.password_validate && (
            <p className="text-val">
              Su contraseñas debe contener al menos <b>8 caracteres</b> entre
              ellos mayúsculas, minúsculas, número y/o caracteres especiales*
            </p>
          )}
        </div>
        <div className="checks-actions">
          <InputCheck
            onChange={handleChange}
            value={stateInputs.confirm}
            name="confirm"
          >
            <p className="m-0">Confirmo que soy paciente oncológico.</p>
          </InputCheck>
          {!validations.confirm && (
            <p className="text-val">
              Debes confirmar que eres paciente oncológico*
            </p>
          )}
          <InputCheck
            onChange={handleChange}
            value={stateInputs.confirmTwo}
            name="confirmTwo"
          >
            <p className="m-0">
              Confirmo que estoy bajo tratamiento
              <br /> de inmunoterapia para el cáncer.
            </p>
          </InputCheck>
          {!validations.confirmTwo && (
            <p className="text-val">
              Debes confirmar que estás bajo tratamiento*
            </p>
          )}
          <InputCheck
            onChange={handleChange}
            value={stateInputs.terms}
            name="terms"
          >
            <p className="m-0">
              Acepto los{" "}
              <a
                href={Terminos}
                rel="noopener noreferrer"
                target="_blank"
                className="txt_white txt_ligth_green"
              >
                Términos & Condiciones
              </a>{" "}
              y las{" "}
              <a
                rel="noopener noreferrer"
                href={Privacidad}
                target="_blank"
                className="txt_white txt_ligth_green"
              >
                Políticas de Privacidad
              </a>
            </p>
          </InputCheck>
          {!validations.terms && (
            <p className="text-val">
              Debes aceptar los Términos & Condiciones*
            </p>
          )}
          <InputCheck
            onChange={handleChange}
            value={stateInputs.privacity}
            name="privacity"
          >
            <p className="m-0">
              <a
                href={Consentimiento}
                rel="noopener noreferrer"
                target="_blank"
                className="txt_white txt_ligth_green"
              >
                Doy mi consentimiento.
              </a>{" "}
            </p>
          </InputCheck>
          {!validations.privacity && (
            <p className="text-val">
              Debes aceptar el aviso de mi consentimiento*
            </p>
          )}
        </div>
        <div onClick={handleValidation}>
          {loading ? (
            <div className="loader"></div>
          ) : (
            <Button
              text="REGISTRAR"
              color="blue"
              size="lg"
              direcctionIcon="right"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SingUp
