import React, { useState } from 'react'
import './styles.scss'


export interface propsTypes {
  stylesImg?: any
  classNameImg?: string
  align?: "flex-end" | "flex-start" | "center"
  justify?: "flex-end" | "flex-start" | "center"
  src: any
  alt: string
  heigth: string
  width: string
  position?: "absolute"
}

const ImageLoad: React.FC<propsTypes> = (props) => {
  const [load, setLoad] = useState(false)

  return(
    <div style={{ 
      display: "flex", 
      position: props.position ? props.position : "relative", 
      height: props.heigth, 
      width: props.width, 
      zIndex:0,
      justifyContent: props.justify, 
      alignItems: props.align }}>
      {
        !load && <div className="lds-ripple"><div></div><div></div></div>
      }
      <img src={ props.src } alt={ props.alt } className={ props.classNameImg } style={{ position: "absolute" }} onLoad={() => setLoad(true)} />
    </div>
  )
}

export default ImageLoad
