import React from 'react'
import HeaderHome from '../../components/headerHome'

import './styles.scss'
// Componments
import {
  NavBar,
  ImageLoad
} from '../../components'
import graffic2 from '../../static/images/graffic-5.svg'
import doctor from '../../static/images/doctor-6.png'
import doctor2 from '../../static/images/doctor-7.png'
import check from '../../static/images/check-2.svg'
import deco from '../../static/images/inmunoterapia-2.png'
import deco2 from '../../static/images/inmunoterapia-3.png'
import step1 from '../../static/images/sangre.svg'
import step2 from '../../static/images/endocrino.svg'
import graffic3 from '../../static/images/graffic-8.png'
import graffic4 from '../../static/images/graffic-9.png'
import icon from '../../static/images/signo-de-exclamacion.png'

import swiper1 from '../../static/images/cansado.png'
import swiper2 from '../../static/images/falta-de-apetito.png'
import swiper3 from '../../static/images/dolor-de-cabeza.png'
import swiper5 from '../../static/images/viruela.png'
import swiper6 from '../../static/images/nausea.png'
import swiper7 from '../../static/images/illness.png'
import swiper9 from '../../static/images/viruela-1.png'

// Import Swiper React components

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from "swiper"
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "swiper/components/pagination/pagination.min.css"
SwiperCore.use([Pagination])


const SideEffects: React.FC = () => {
  const paginationRef = React.useRef(null)

  return (
    <div className="home-contain side-effects-contain">
       
      <img className="graffic-home2" src={graffic2} alt="" />
      <HeaderHome return />
      <section className="side-effects-content p-top">
        <h2 className='fw-normal'><b>¿Qué <span className='txt_green_ligth'>efectos secundarios</span> podés presentar o sentir</b><br /> luego del tratamiento con Pembrolizumab? <sup>1,2</sup></h2>
        <ImageLoad classNameImg='figure-2' src={doctor} alt="sistema inmune" justify='center' heigth="21em" width="100%" />
      </section>

      <section className="section-blue">
        <p className='txt_white'>Al igual que todos los medicamentos, los<br />pacientes tratados con Pembrolizumab <br /><b>pueden presentar algunos efectos secundarios.</b></p>
      </section>

      <div className="card-green">
        <span className='deco'></span>
        <span className='icon'>
          <ImageLoad src={check} alt="Icono de aprovacion" justify='center' heigth="2em" width="100%" />
        </span>
        <p>Es importante recordar que esto no significa que los vayas a presentar, pero es importante que los conozcas para poder estar atento <b>y consultar con tu médico en caso de ser necesario.</b></p>
      </div>

      <section className="side-effects-content">
        <p>A continuación, te cuento <b>algunos efectos secundarios más frecuentes</b> que presentaron algunos pacientes al recibir Pembrolizumab, ya sea en tratamiento único o en combinación con otros tratamientos.<br /><br /></p>
        <h2>Algunos pacientes han<br />informado que presentan: </h2>
      </section>
      <Swiper
        pagination={{ el: paginationRef.current }}
        centeredSlides
        loop
        spaceBetween={20}
        slidesPerView={2}
        onSlideChange={() => false}
        onSwiper={(swiper) => false}
      >
        <SwiperSlide className='swiper-card'>
          <div className="image">
            <img src={swiper1} alt="" />
          </div>
          <p>SENSACIÓN DE FATIGA O CANSANCIO</p>
        </SwiperSlide>
        <SwiperSlide className='swiper-card'>
          <div className="image">
            <img src={swiper2} alt="" />
          </div>
          <p>DISMINUCIÓN DEL APETITO</p>
        </SwiperSlide>
        <SwiperSlide className='swiper-card'>
          <div className="image">
            <img src={swiper3} alt="" />
          </div>
          <p>DOLOR DE CABEZA</p>
        </SwiperSlide>
        <SwiperSlide className='swiper-card'>
          <div className="image">
            <img src={swiper5} alt="" />
          </div>
          <p>PICAZÓN Y APARICIÓN DE BROTE O SARPULLIDO EN LA PIEL</p>
        </SwiperSlide>
        <SwiperSlide className='swiper-card'>
          <div className="image">
            <img src={swiper6} alt="" />
          </div>
          <p>DOLOR EN MÚSCULOS HUESOS Y/O ARTICULACIONES</p>
        </SwiperSlide>
        <SwiperSlide className='swiper-card'>
          <div className="image">
            <img src={swiper7} alt="" />
          </div>
          <p>TOS</p>
        </SwiperSlide>
        <SwiperSlide className='swiper-card'>
          <div className="image">
            <img src={swiper9} alt="" />
          </div>
          <p>MANCHAS BLANCAS EN LA PIEL (VITILIGO)</p>
        </SwiperSlide>
        <div ref={paginationRef} className="swiper-pagination"></div>
      </Swiper>
      <section className="side-effects-content">
        <img className="figure-1" src={deco} alt="" />
      </section>
      <section className="steps-contain steps-contain-2">
        <span className='top'></span>
        <span className='line'></span>
        <span className='bottom'></span>
        <img className='deco' src={deco2} alt="" />
        <div className="step">
          <div className="icon">
            <img src={step1} alt="" />
          </div>
          <div className='content'>
            <p><b>Alteraciones detectables en los análisis de sangre:</b> Anemia, aumento de la glucosa, aumento de las enzimas hepáticas, alteraciones en los valores de Sodio, Potasio y Calcio </p>
          </div>
        </div>
        <div className="step">
          <div className="icon">
            <img src={step2} alt="" />
          </div>
          <div className='content'>
            <p><b>Alteración en el funcionamiento del sistema endócrino (glándulas):</b> Hipotiroidismo, hipertiroidismo y otras alteraciones glandulares.</p>
          </div>
        </div>
      </section>
      <section className="section-blue">
        <img className='graffic' src={graffic3} alt="" />
        <div className='section-content'>
          <p>Ante la presencia de algún efecto adverso luego de recibir el tratamiento es importante que lo informés a tu médico, incluso si este no se encuentra dentro de los mencionados en el listado anterior.</p>
          <img className='img-1' src={icon} alt="signo de admiracion" />
          <p><b>Acordate que estos son solo algunos de los efectos secundarios que podés presentar en tu tratamiento con Pembrolizumab.</b></p>
          <img className='img-2' src={graffic4} alt="" />
        </div>
      </section>

      <section className='float-card'>
        <img className='figure' src={doctor2} alt="" />
        <div className="card-content">
          <p>Si querés conocer información más detallada podés acceder a ella en el prospecto completo</p>
          <a href="https://www.globalfarm.com.ar//msd_pdf/keytruda/keytruda.pdf" className='btn' type='a'>Haciendo click acá</a>
        </div>
      </section>

      <div className="references">
        <p>Referencias: <br /><br />
          1.	INFORMACIÓN PARA EL PRESCRIPTOR KEYTRUDA® Pembrolizumab.<br />
          2.	https://es.oncolink.org/tratamiento-del-cancer/oncolink-rx/Pembrolizumab-keytruda-r
        </p>
      </div>

      <NavBar />
    </div>
  )
}

export default SideEffects

