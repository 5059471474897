import React, { useState, useEffect } from 'react'
import './styles.scss'
import arrowLeft from '../../static/images/arrow-left-white.svg'
import icon from '../../static/images/icon.svg'
import user from '../../static/images/user.png'
import { Link, useHistory } from "react-router-dom"
// SessionStorage
import SessionStorage from "../../storage"

const STORAGE = new SessionStorage();

export interface propsTypes {
  return?: boolean
}

const HeaderHome: React.FC<propsTypes> = (props) => {
  const [scrollTop, setScrollTop] = useState(true)
  let history = useHistory();


  window.onscroll = () => {
    // Obtenemos la posicion del scroll en pantall
    var scroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (scroll > 50) {
      setScrollTop(false)
    } else {
      setScrollTop(true)
    }
  }  

  useEffect(() => {   
    var scroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (scroll > 10) {
      setScrollTop(false)
    } else {
      setScrollTop(true)
    }
  },[])

  return (
    <header className={`header-home ${!scrollTop && "header-home-scroll"}`}>
      {
        props.return ? 
          <div className="action" onClick={() => history.goBack()}>
            <img src={arrowLeft} alt="Action return" />
          </div>:
          <div>
            {
              STORAGE.getUserName() ? (
                <React.Fragment>
                  <p>Hola</p>
                  <h4>{ STORAGE.getUserName()  }</h4>
                </React.Fragment>
              ) :  <h4>Bienvenido</h4>
            }
          </div>
      }
      <Link to="/home" className="logo">
        <img src={icon} alt="Logo Apolo" />
      </Link>
      <Link to="/personal-file" className="user-procfile">
        <img className="user-image" src={user} alt="Imagen de usuario" />
      </Link>
    </header>
  )
}

export default HeaderHome
