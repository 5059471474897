import React, { useEffect } from 'react';
// import logo from './static/images/logo.svg';
import { BrowserRouter as Router, Switch, Route, useHistory, useLocation } from "react-router-dom"
import { routes, routesAuth } from './navigation/routes'
import PageError from './modules/pageError'
// SessionStorage
import SessionStorage from "./storage"
import OuthApi from "./api/outh.api"



import "./App.css"
import DisabledSplash from './modules/disabledSplash';

const API = new OuthApi();
const STORAGE = new SessionStorage();

const PageViews = () => {
    let location = useLocation()
    const history = useHistory()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        (async () => {
            await API.LastAction()

            const response2 = await API.RefreshRoken()
            switch (response2?.status) {
                case 200:
                    STORAGE.saveSession({ ...response2.data, auth: true, email: STORAGE.getuserMail(), username: STORAGE.getUserName() })
                    break;
                default:
                    STORAGE.clearStorage()
                    history.push("/")
                    break;
            }
        })()
    }, [location.pathname, history])

    return (<React.Fragment></React.Fragment>)
}

const PrivateRoutes: React.FC = () => {
    const history = useHistory()

    if (!STORAGE.getAuth()) {
        history.push("/")
    }

    return (
        <div style={{ position: "relative" }}>
            <PageViews />
            {
                routesAuth.map((item: any, index: number) => (
                    <Route key={index} path={item.path} exact={item.exact} component={item.component} />
                ))
            }
            
        </div>

    )
}

const App: React.FC = () => {


    return (
        <div className='container-desktop'>
            <div className="container-movil">


                 { 
                    /* addAlert && <div id='alert-install-ios'>
                         <span onClick={() => setAddAlert(false)} >x</span>
                         <h1>¡Instala nuestra App IOS!</h1>
                         <button id='btn-alert-install-ios'>Instalar App</button>
                     </div> */
                }

                {/* <Router>
                    <Switch>
                        {routes.map((item: any, index: number) => (
                            <Route key={index} path={item.path} exact={item.exact} component={item.component} />
                        )
                        )}
                        <PrivateRoutes />
                        <Route path='*' component={PageError} />
                    </Switch>
                </Router> */}
                <DisabledSplash />
            </div>
        </div>
    )
}

export default App