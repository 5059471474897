import React, { useEffect, useState } from "react"
import OuthApi from "../../api/outh.api";
import "./styles.scss"

const API = new OuthApi();


export interface propsTypes{
  text?: string
}
const TipDay: React.FC<propsTypes> = (props) => {
   const [tipsToday, guardarTipToday] = useState<any>([]);

   useEffect(() => {
     (async () => {
       const response =  await API.getTip()
       guardarTipToday(response?.data?.message);
     })();
   }, []);
  return (
    <div className="card-tip-day">
      <h2>TIP APOLO</h2>
      <p dangerouslySetInnerHTML={{ __html: tipsToday }}></p>
      <br/>
      <p className="reminder">{props.text || 'Encuentra este y más tips en el botón Tip semanal'}</p>
    </div>
  )
}

export default TipDay
