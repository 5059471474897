import React, { useState, useEffect } from 'react'
import Header from '../../components/header'
// Componments
import {
    Button,
    InputText,
    ImageLoad
} from '../../components'
// Styles
import './styles.scss'
// Images
import doctor from '../../static/images/doctor-1.svg'
import arrowRigth from '../../static/images/arrow-rigth.svg'
import grafic from '../../static/images/figura-1.png'
// Context
import { useHistory, Link } from "react-router-dom"
// api
import OuthApi from "../../api/outh.api"
// SessionStorage
import SessionStorage from "../../storage"
import Swal from "sweetalert2"


const API = new OuthApi();
const STORAGE = new SessionStorage();


const SingUp: React.FC = () => {
    let history = useHistory();
    const [loading, setLoading] = useState(false)

    const [stateInputs, setStateInputs] = useState({
        email: "",
        password: ""
    })
    const [stateUpdate, setStateUpdate] = useState(false)
    const [validations, setValidation] = useState(true)

    const handleChange = (e:any) => {
        setStateInputs(Object.assign(stateInputs, {
            [e.target.name]: e.target.value
        }))
        setStateUpdate(!stateUpdate)
    }

    useEffect(() => {
        
        
        if (STORAGE.getRefreshToken() && STORAGE.getRefreshToken() !== "") {
            (async () => {
                const response = await API.RefreshRoken()
    
                switch (response?.status) {
                    case 200:
                        STORAGE.saveSession({...response.data, auth: true, email: STORAGE.getuserMail(), username: STORAGE.getUserName()})
                        setTimeout(() => {
                            history.push("/home")
                        }, 300);
                        break;
                    default:
                        STORAGE.clearStorage()
                        break;
                }
            })() 
        }
    },[history])

    const handleLogIn = async () => {
        setValidation(true)
        setLoading(true)

        if (stateInputs.email === "" && stateInputs.password === "") {
            setValidation(false)
        } else {
            const response = await API.logIn(stateInputs.email, stateInputs.password)

            switch (response?.status) {
                case 200:
                    STORAGE.saveSession({...response.data, auth: true, email: stateInputs.email})
                    setTimeout(() => {
                        history.push("/home")
                    }, 300);
                    break;
                default:
                    Swal.fire({
                        icon: 'error',
                        title: 'El nombre de usuario o contraseña es incorrecto',
                    })
                    break;
            }
        }
        setLoading(false)
    }


    return (
        <div className="login-content-start">
            <img className="grafic" src={grafic} alt="" />
            <div className="gradient-bottom"></div>

            <Header url={"/"} />
            <div className="content">
                <div className="figure-doctor d_flex justify_end">
                    <ImageLoad classNameImg='doctor-login' src={doctor} alt="Figura de doctor" justify='flex-end' heigth="22em" width="100%" />
                    <div className="text-figure">
                        <h2 className="txt_09 txt_bold txt_primary">¡TE DAMOS LA BIENVENIDA!</h2>
                        <p className="txt_09 txt_primary">Este espacio está creado para vos.<br/> Para tu salud, <b>para tu bienestar</b>.</p>
                    </div>
                </div>
                <div>
                    <h1 className="txt_bold txt_primary">INICIAR SESIÓN</h1>
                </div>
                <div className="inputs-actions">
                    <form action="">
                        <InputText onChange={handleChange} value={stateInputs.email} name="email" type='email' placeholder="Correo Electrónico"/>
                        <InputText onChange={handleChange} value={stateInputs.password} name="password" type='password' placeholder="Contraseña" />
                    </form>

                    {
                        ! validations && <p className='text-val'>Completa todos los campos*</p>
                    }

                    <p className="txt_white txt_09">¿Olvidaste tu contraseña? <Link to="/forgot-password" href="#" className="txt_white txt_ligth_green">Hacé clic aquí.</Link></p>
                </div>
                <div onClick={handleLogIn}>
                    {
                        loading ? <div className="loader"></div> : <Button  text="INGRESAR AHORA" color="blue" size="lg" icon={arrowRigth} direcctionIcon="right"/>
                    }
                </div >
            </div>
        </div>
    )
}

export default SingUp
