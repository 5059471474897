import React, { useEffect } from "react"
import Header from "../../components/header"
// Componments
import { Button } from "../../components"
// Styles
import "./styles.scss"
// Images
import doctor from "../../static/images/doctor-10.svg"
import grafic from "../../static/images/figura-1.png"
//import video from "../../static/videos/muestra.mp4"
// Context
import { useHistory } from "react-router-dom"
// api
import OuthApi from "../../api/outh.api"
// SessionStorage
import SessionStorage from "../../storage"

const API = new OuthApi()
const STORAGE = new SessionStorage()

const Start: React.FC = () => {
  let history = useHistory()

  useEffect(() => {
    if (STORAGE.getRefreshToken() && STORAGE.getRefreshToken() !== "") {
      ;(async () => {
        const response = await API.RefreshRoken()

        switch (response?.status) {
          case 200:
            STORAGE.saveSession({
              ...response.data,
              auth: true,
              email: STORAGE.getuserMail(),
              username: STORAGE.getUserName(),
            })
            setTimeout(() => {
              history.push("/home")
            }, 300)
            break
          default:
            STORAGE.clearStorage()
            break
        }
      })()
    }
  }, [history])

  return (
    <div className="start-content">
      <img className="grafic" src={grafic} alt="" />
      <div className="gradient-bottom"></div>

      <Header />
      <div className="content">
        <div className="figure-doctor d_flex justify_end">
          <div className="text-figure">
            <h2 className="txt_bold txt_primary">¡TE DAMOS LA BIENVENIDA!</h2>
            <p className="txt_1 txt_primary">
              Este espacio está creado para vos. Para tu salud,{" "}
              <b>para tu bienestar</b>.
            </p>
            {/* 
              <p className="txt_1 txt_primary">
                En el siguiente video te contaré de que se trata, <b>Apolo</b>.
              </p>
            */}
          </div>
        </div>
        <div className="video">
          <iframe src="https://apoloapp.blob.core.windows.net/$web/Video%20Apolo_V4%20(1).mp4" className="video-src" title="Video introductorio">Tu navegador no admite el elemento <code>video</code>.</iframe>
        </div>
        {/*
          <div className="video" style={{border: "2px solid gray",}}>
            <iframe src={video} className="video-src" title="Video introductorio">
              Tu navegador no admite el elemento <code>video</code>.
            </iframe>
          </div>
        */}
        <div className="buttons-actions d_flex">
          <p className="txt_09 txt_primary">¿Ya tienes una cuenta?</p>
          <Button to="/login" text="INICIAR SESIÓN" size="lg" />
          <p className="txt_09 txt_primary">¿No tienes una cuenta?</p>
          <Button
            to="/sign-up-admin"
            size="lg"
            shadow
            text="REGISTRARSE"
            color="white"
          />
        </div>
        <img className="doctor-bottom" src={doctor} alt="" />
      </div>
    </div>
  )
}

export default Start
