import React, { useEffect, useState } from "react";
import OuthApi from "../../api/outh.api";
import "./styles.scss";

const API = new OuthApi();
export interface propsTypes {}

const PreviousTipItem: React.FC = (props: any) => {
  const [active, setActive] = useState(false);
  return (
    <div className={`previous-tip-item ${active && "active"}`}>
      {props.children}

      <div className="shadown-bottom">
        {active ? (
          <div className="item-btn" onClick={() => setActive(false)}>
            + DISMINUIR
          </div>
        ) : (
          <div className="item-btn" onClick={() => setActive(true)}>
            + AMPLIAR
          </div>
        )}
      </div>
    </div>
  );
};

const PreviousTip: React.FC<propsTypes> = (props) => {
  const [tipsOld, guardarTips] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await API.getAllTips();
      guardarTips(response?.data?.message);
    })();
  }, []);
  return (
    <div className="previous-tip">
      <h2>TIPS DESTACADOS</h2>
      <div className="items">
        {tipsOld.length > 0 ? (tipsOld?.map((tip: any) => (
              <PreviousTipItem><p dangerouslySetInnerHTML={{ __html: tip.text }}></p></PreviousTipItem>
              

            ))):
              <p>No hay tips anteriores</p>
            }
      </div>
    </div>
  );
};

export default PreviousTip;
