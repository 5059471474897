/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Header from '../../components/header'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
// Componments
import {
  Button,
  InputText
} from '../../components'
// Styles
import './styles.scss'
// Images
import doctor from '../../static/images/doctor-9.png'
import arrowRigth from '../../static/images/arrow-rigth.svg'
import grafic from '../../static/images/graffic-1.svg'
import { useHistory } from "react-router-dom"
// api
import OuthApi from "../../api/outh.api"
import SessionStorage from "../../storage"
const STORAGE = new SessionStorage();
const API = new OuthApi();

interface ParamsValues {
  token: string
  code: string
}

const Activation: React.FC = () => {
  const params = useParams<ParamsValues>()
  const [code, setCode] = useState("")
  const [validation, setValidation] = useState(true)
  const [loading, setLoading] = useState(false)
  let history = useHistory();

  const handleChange = (e:any) => {
    setCode(e.target.value)
  }

  useEffect(() => {
    if(params.code && "" !== params.code ) {
      setCode(params.code)
    }
  },[])
  
  
  useEffect(() => {
    if(params.code && "" !== params.code && code !== "") {
      handleSend()
    }
  },[code])

  const handleSend = async () => {
    setLoading(true)
    if (code !== "") {
      const response = await API.ActivateUser(code.toLocaleLowerCase())

      switch (response?.status) {
        case 200:
          Swal.fire({
            title: 'Tu cuenta ha sido activada',
            text: 'Ya podés ingresar a APOLO',
            confirmButtonText: '¡Comenzá la experiencia ahora!',
          }).then((result) => {
            if (result.isConfirmed) {
              STORAGE.saveSession({...response.data.user, auth: true, email: response.data.user.email})
              history.push("/home")
            } 
          })
        break;
        default:
          Swal.fire({
            icon: 'error',
            title: 'Activación no exitosa',
            text: 'Inténtalo más tarde'
          })
        break;
      }
    } else {
      setValidation(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    if(params.token)
      STORAGE.saveRegistryToken(params.token)
	}, []);

  return (
    <div className="login-content">
      <img className="grafic" src={grafic} alt="" />
      <div className="gradient-bottom"></div>

      <Header return />
      <div className="content">     
      <h2 className='txt_center fw-normal txt_black'>INGRESE EL <br/><b>CÓDIGO DE CONFIRMACIÓN</b><br/> PARA CONTINUAR CON EL REGÍSTRO</h2>
        <div className="inputs-actions">
          <InputText onChange={handleChange} value={code} placeholder="Código"/>
          {
           !validation && <p className='text-val'>Ingresa un código*</p>
          }
        </div>
        <div onClick={handleSend}>
          {
            loading ? <div className="loader"></div> : <Button text="CONFIRMAR CÓDIGO" color="blue" size="lg" icon={arrowRigth} direcctionIcon="right"/>
          }
        </div>
        <img src={doctor} alt="" />
      </div>
    </div>
  )
}

export default Activation
