import React, { useState } from 'react'
import Header from '../../components/header'
import Swal from 'sweetalert2'
// Componments
import {
  Button,
  InputText,
  ImageLoad
} from '../../components'
// Styles
import './styles.scss'
// Images
import doctor from '../../static/images/doctor-6.png'
import arrowRigth from '../../static/images/arrow-rigth.svg'
import grafic from '../../static/images/graffic-1.svg'
// api
import OuthApi from "../../api/outh.api"


const API = new OuthApi();


const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState("")
  const [validation, setValidation] = useState(true)

  const handleChange = (e:any) => {
    setCode(e.target.value)
  }

  const handleSend = async () => {
    setLoading(true)
    if (code !== "") {
      const response =await API.ForgotPassword(code)

      switch (response?.status) {
        case 200:
          setCode("")
          Swal.fire({
            title: 'Te hemos enviando un correo para reestablecer tu contraseña.',
            text: 'Revisa tu correo',
          })
          break;
        default:
          Swal.fire({
            title: 'Te hemos enviando un correo para reestablecer tu contraseña.',
            text: 'Revisa tu correo',
          })
          break;
      }
    } else {
      setValidation(false)
    }
    setLoading(false)
  }
  
  return (
    <div className="login-content">
      <img className="grafic" src={grafic} alt="Apolo" />
      <div className="gradient-bottom"></div>

      <Header return />
      <div className="content">     
        <h2 className='txt_center txt_black'>Recuperación de contraseña</h2>
        <div className="inputs-actions">
          <InputText onChange={handleChange} value={code} type="email" placeholder="Ingresa tu correo"/>
          {
           !validation && <p className='text-val'>Ingresa un correo*</p>
          }
        </div>
        <div onClick={handleSend}>
          {
            loading ? <div className="loader"></div> : <Button text="ENVIAR" color="blue" size="lg" icon={arrowRigth} direcctionIcon="right"/>
          }
        </div>
        <img className='doctor' src={doctor} alt="Apolo" />
         <ImageLoad classNameImg='doctor' src={doctor} alt="Apolo" justify='center' heigth="4em" width="100%" />

      </div>
    </div>
  )
}

export default ForgotPassword;
