import React from "react";
import "./styles.scss";
import logo from '../../static/images/logo.svg'
import { ImageLoad } from "../../components";
const DisabledSplash: React.FC = () => {
  return (
    <div className="splash">
      <ImageLoad classNameImg='logo' src={logo} alt="Logo Apolo" justify='center' heigth="4em" width="100%" />
      <p><strong>En breve estaremos de vuelta</strong><br /><br /><br />
      En este momento estamos actualizando nuestra app para que muy pronto puedas disfrutar la mejor experiencia.</p>
      <small>
        D.R. © 2024 Merck & Co., Inc., Rahway, NJ, USA y sus filiales.<br />
        Todos los derechos reservados. <br /><br />
        Prohibida su reproducción total o parcial. <br /><br />
        MSD ARGENTINA S.R.L. Cazadores de Coquimbo 2841/57, Piso 4 (B1605AZE) - Munro - Vicente López - Bs. As. / Tel.: 11-6090-7200 //www.msd.com.ar // recepcion_argentina@merck.com <br /><br />
        AR-KEY-01443
      </small>
    </div>
  );
};

export default DisabledSplash;
